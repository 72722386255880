<template>
  <b-table :fields="table.fields" :items="tableItems" thead-class="table-header text-white text-center" :busy="loading"
           tbody-tr-class="table-row" striped small caption-top @row-clicked="edit" :class="tableClasses">
    <template #cell(image_type)="data">
      <span v-if="data.item.image_type">
        <b-badge class="ml-1" :style="badgeStyle(data.item)">{{ data.item.image_type.displayname }}</b-badge>
      </span>
    </template>
    <template #cell(default)="data">
      <span v-if="data.item.default"><b-icon-check font-scale="2"/></span>
      <span v-else><b-icon-x font-scale="2"/></span>
    </template>
    <template #cell(inactive)="data">
      <span v-if="data.item.inactive">Ja</span>
      <span v-else>Nein</span>
    </template>
    <template #custom-foot>
      <b-tr class="table-footer">
        <b-th v-for="(field, index) in table.fields" :key="index"/>
      </b-tr>
    </template>
    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner variant="primary" class="align-middle"/>
      </div>
    </template>
  </b-table>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ImagesTable",
  props: ['tableItems', 'loading'],
  data() {
    return {
      table: {
        fields: [
          {key: 'displayname', label: 'Bezeichnung'},
          {key: 'description', label: 'Beschreibung'},
          {key: 'image_type', label: 'Imagetyp', tdClass: 'text-center'},
          {key: 'count', label: 'Anzahl', tdClass: 'text-center'},
          {key: 'os_version', label: 'Windowsversion'},
          {key: 'version', label: 'Imageversion', tdClass: 'text-center'},
          {key: 'default', label: 'Standardimage', tdClass: 'text-center'},
          {key: 'inactive', label: 'Inaktiv', tdClass: 'text-center'}
        ]
      },
    }
  },
  methods: {
    edit(data) {
      this.$emit('edit-images', data)
    },
    badgeStyle(data) {
      return {
        'color': data.color_font,
        'background-color':data.color_background
      }
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    tableClasses() {
      if(this.userThemeId === 2) {
        return []
      }
      return ['text-white']
    }
  }
}
</script>

<style lang="scss">
@import '../../../assets/styles/Tables';
</style>
